<template>
	<v-app id="default-layout" light>
		<toolbar v-if="!$route.path.includes('agegate')" />
		<nuxt style="flex: 1; width: 100%;" />
		<Footer />
		<cookie-banner />
	</v-app>
</template>

<script>
export default {
	name: 'DefaultLayout',
	components: { Toolbar: () => import('~/components/website/Toolbar'), CookieBanner: () => import('~/components/website/CookieBanner'), Footer: () => import('~/components/website/Footer') },
	mounted(){
		this.$vuetify.theme.dark = false
	}
}
</script>

<style lang="scss" src="~/assets/css/website/index.scss" />
