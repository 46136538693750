import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e8b259e8 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _0ba92c44 = () => interopDefault(import('../pages/agegate.vue' /* webpackChunkName: "pages/agegate" */))
const _01eb2e16 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _40c877e0 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _4f0e41c2 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _4561fb64 = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _234a498a = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _1ecbc67a = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _36d93403 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _f241657e = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _40321d56 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _92a27a64 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _0362c5e1 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _704af805 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _803b1388 = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _352c9e53 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _4d8ddebd = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _b7128ea0 = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _4a77081f = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _c430702e = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _bf3a577e = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _1069559a = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _28408ad2 = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _77690786 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _59c57ed9 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _0e0797e4 = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _509b585f = () => interopDefault(import('../pages/admin/tickets-check/index.vue' /* webpackChunkName: "pages/admin/tickets-check/index" */))
const _01596953 = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _5b2b681b = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _1242ba45 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _72a8d4d1 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _ad23b338 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _76388238 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _384acba4 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _3548d624 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _3068da78 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _deab7e06 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _7e6f594e = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _cfc9126a = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _c5279810 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _51880bcb = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _5dc8d919 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _191d534c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3943167b = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _d8d38e5c = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _3aa37078 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _5277249e = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _1ae762dc = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _b4a5c13c = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _b20a66ee = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _36bb2718 = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _46168647 = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _2611ca9d = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _8cb812fc = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _170d1282 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _6d268923 = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _69bb36bb = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _0a1ebc76 = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _e8b259e8,
    name: "admin"
  }, {
    path: "/agegate",
    component: _0ba92c44,
    name: "agegate"
  }, {
    path: "/cupom",
    component: _01eb2e16,
    name: "cupom"
  }, {
    path: "/faq",
    component: _40c877e0,
    name: "faq"
  }, {
    path: "/perfil",
    component: _4f0e41c2,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _4561fb64,
    name: "regulamentos"
  }, {
    path: "/vencedores",
    component: _234a498a,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _1ecbc67a,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _36d93403,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _f241657e,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _40321d56,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _92a27a64,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _0362c5e1,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _704af805,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _803b1388,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _352c9e53,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _4d8ddebd,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _b7128ea0,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _4a77081f,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _c430702e,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _bf3a577e,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _1069559a,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _28408ad2,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _77690786,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _59c57ed9,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _0e0797e4,
    name: "admin-tickets"
  }, {
    path: "/admin/tickets-check",
    component: _509b585f,
    name: "admin-tickets-check"
  }, {
    path: "/admin/tokens",
    component: _01596953,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _5b2b681b,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _1242ba45,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _72a8d4d1,
    name: "admin-winners"
  }, {
    path: "/forgot",
    component: _ad23b338,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _76388238,
    name: "auth-login"
  }, {
    path: "/register",
    component: _384acba4,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _3548d624,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _3068da78,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _deab7e06,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _7e6f594e,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _cfc9126a,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _c5279810,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _51880bcb,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _5dc8d919,
    name: "admin-users-create"
  }, {
    path: "/",
    component: _191d534c,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _3943167b,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _d8d38e5c,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _3aa37078,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _5277249e,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _1ae762dc,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _b4a5c13c,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _b20a66ee,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _36bb2718,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _46168647,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _2611ca9d,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _8cb812fc,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _170d1282,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _6d268923,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _69bb36bb,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _0a1ebc76,
    name: "unsubscribe-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
